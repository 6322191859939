import { ValidationErrorSeverity, type ValidationResult } from "@component-utils/validations";
import { computed, ref, type Ref } from "vue";
import type { ValidationProps } from "./types";
import { globalLocalize, useLocalize } from "@component-utils/localization";
import Utils from "~/features/utils";

export function useValidation<T> (
  validatorSubject: Ref<undefined | null | T>,
  props: ValidationProps<T>,
  validatorDefault?: Ref<(value: undefined | null | T) => ValidationResult>
) {
  const validator = computed(() => props.validator ?? validatorDefault?.value)

  const validationActive = ref(props.validationTrigger === 'immediate')
  const validationResult = computed(() => validator.value && validationActive.value && validator.value(validatorSubject.value) || null)

  const isValidationError = computed(() => validationResult.value?.[0] === ValidationErrorSeverity.Error)
  const isValidationWarning = computed(() => validationResult.value?.[0] === ValidationErrorSeverity.Warning)

  return {
    validationActive,
    validationResult,
    isValidationError,
    isValidationWarning
  }
}

export function isValidationError (validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Error
}

export function isValidationWarning (validationResult: Ref<ValidationResult>) {
  return validationResult.value?.[0] === ValidationErrorSeverity.Warning
}

export function createPasswordRequirements(password: string | null | undefined) {
  const localize = useLocalize('component-library.validations.password.requirements')
  const requirements = [
    {
      text: localize('min_length'),
      isValid: (password?.length || 0) >= 6
    },
    {
      text: localize('lowercase'),
      isValid: /[a-z]/.test(password || '')
    },
    {
      text: localize('uppercase'),
      isValid: /[A-Z]/.test(password || '')
    },
    {
      text: localize('number'),
      isValid: /[0-9]/.test(password || '')
    },
    {
      text: localize('special_char'),
      isValid: /[!@#$%^&*(),.?":{}|<>]/.test(password || '')
    }
  ]

  return requirements
}

export function createDefaultValidation<T> (props: { required?: boolean, id?: string, showRequirements?: boolean }, type?: 'email' | 'password' | 'multiselect'): Ref<(value: T | undefined | null) => ValidationResult> {
  switch (type) {
    case 'multiselect': return computed(() => (value: T | undefined | null) => {
      if (!value || !Array.isArray(value) || value.length === 0) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
        else return null
      }
      return null
    })
    case 'email': return computed(() => (value: T | undefined | null) => {
      if (!value || typeof value !== 'string' || !value.trim()) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.email.empty')]
        else return null
      }
      if (!Utils.isEmailValid(value)) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.email.format')]
      return null
    })
    case 'password': return computed(() => (value: T | undefined | null) => {
      if (!value || typeof value !== 'string' || !value.trim()) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.password.empty')]
        else return null
      }
      // Not show message if requirements are shown
      if (props.showRequirements) {
        return null
      }
      
      const requirements = createPasswordRequirements(value as string)
      if (!requirements.every(req => req.isValid)) {
        return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.password.format')]
      }
      
      return null
    })
    default: return computed(() => (value: T | undefined | null) => {
      if (!value || (typeof value === 'string' && !value.trim())) {
        if (props.required) return [ValidationErrorSeverity.Error, globalLocalize('component-library.validations.generic.empty')]
        else return null
      }
      return null
    })
  }
}